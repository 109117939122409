var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("goals-table", {
            attrs: {
              tableName: _vm.goalTables.USER_GOALS,
              goals: _vm.goals,
              loading: _vm.loading
            },
            on: { updateOptions: _vm.updateOptions }
          })
        ],
        1
      ),
      _c(
        "v-fab-transition",
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-5",
                              attrs: {
                                color: "primary",
                                fab: "",
                                bottom: "",
                                right: "",
                                fixed: ""
                              },
                              on: { click: _vm.newGoal }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("layout.accountNavMenu.newGoal")))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }